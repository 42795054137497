import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../components/common/customMedia'
import { Reveal, Animation } from 'react-genie'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { Image } from '../components/common/Image'
import { SpBr } from '../components/common/SpBr'
import { PcBr } from '../components/common/PcBr'
import { Jumbotron } from '../components/common/Jumbotron'
import { Breadcrumb } from '../components/common/Breadcrumb'

const CoverImage = require('../images/kakaritsuke_main.jpg')

const IndexPage = () => (
  <Layout>
    <SEO 
      title='かかりつけ薬剤師制度について | シンビ堂'
      description='患者様にご指名して頂くことにより、専属の薬剤師をもつことができる「かかりつけ薬剤師」制度についてご紹介します。十分な経験をもったシンビ堂の薬剤師がみなさまの服薬に関する管理を担当させていただきます。'
      keywords={['シンビ堂','かかりつけ薬剤師制度','服薬記録','薬剤師']}
    />
    <h1>かかりつけ薬剤師制度 服薬管理 薬剤師 | シンビ堂</h1>

    <Container>
      <Jumbotron imageSrc={CoverImage} jaTitle={`かかりつけ\n薬剤師制度とは？`} objectPosition={15} />
      <BreadcrumbWrapper>
        <Breadcrumb color='blue' current='かかりつけ薬剤師制度とは？' />
      </BreadcrumbWrapper>
      <Reveal delay={500} animation={Animation.FadeInUp}>
        <SubTitle>2016年4月から「かかりつけ薬剤師」制度がスタートしています。<PcBr />ここでは制度の内容やかかりつけ薬剤師をもつメリットについてご紹介します。</SubTitle>
      </Reveal>

      <Reveal delay={600} animation={Animation.FadeInUp}>
        <ImageWrapper><Image path='kakaritsuke@2x.png' /></ImageWrapper>
      </Reveal>
      
      <Reveal delay={800} animation={Animation.FadeInUp}>
        <SubTitle>かかりつけ薬剤師制度とは、患者様に薬剤師を指名して頂くことにより、<PcBr />専属の薬剤師をもつことができる制度です。</SubTitle>
      </Reveal>

      <MeritContainer color='pink'>
        <Reveal delay={900} animation={Animation.FadeInUp}>
          <MeritTitle color='pink'>MERIT1</MeritTitle>
        </Reveal>

        <Reveal delay={1000} animation={Animation.FadeInUp}>
          <MeritSubTitle color='pink'>専属の薬剤師が<PcBr />あなたの<SpBr />お薬の事を全て把握している</MeritSubTitle>
        </Reveal>

        <Reveal delay={1100} animation={Animation.FadeInUp}>
          <Image1Wrapper><Image path='kakaritsuke1@2x.png' /></Image1Wrapper>
        </Reveal>

        <Reveal delay={1200} animation={Animation.FadeInUp}>
          <MeritDetail color='pink'>担当の薬剤師が、あなたが服用している全てのお薬や市販薬、健康食品、<PcBr />サプリメントなどをまとめて把握して、重複したお薬が出ていないか、お薬同士や食品との<PcBr />相互作用がでていないかなど、お薬の服用に際して注意点などをお伝えします。</MeritDetail>
        </Reveal>
      </MeritContainer>

      <MeritContainer color='white'>
        <Reveal delay={1300} animation={Animation.FadeInUp}>
          <MeritTitle color='white'>MERIT2</MeritTitle>
        </Reveal>

        <Reveal delay={1400} animation={Animation.FadeInUp}>
          <MeritSubTitle color='white'>体調の変化の確認や薬の管理をすることで<PcBr />安全・安心に薬を使用できる</MeritSubTitle>
        </Reveal>

        <Reveal delay={1500} animation={Animation.FadeInUp}>
          <Image2Wrapper><Image path='kakaritsuke2@2x.png' /></Image2Wrapper>
        </Reveal>

        <Reveal delay={1600} animation={Animation.FadeInUp}>
          <MeritDetail color='white'>来局いただいた際に、過去の服薬記録も含めて、<PcBr />患者さまの服薬後の経過を継続してチェックしていきます。<PcBr />患者さまのお薬の効果や体調の変化をみて、必要に応じて医療機関へ連絡も行います。<PcBr />また余っているお薬が多数ある場合、薬局にお持ちいただければ<PcBr />次回の処方調整を提案したり、ご自宅でのお薬の確認・整理を行うこともあります。</MeritDetail>
        </Reveal>
      </MeritContainer>

      <MeritContainer color='pink'>
        <Reveal delay={1700} animation={Animation.FadeInUp}>
          <MeritTitle color='pink'>MERIT3</MeritTitle>
        </Reveal>
        
        <Reveal delay={1800} animation={Animation.FadeInUp}>
          <MeritSubTitle color='pink'>開局時間内に加え、<br />夜間・休日の対応も受けられる</MeritSubTitle>
        </Reveal>  

        <Reveal delay={1900} animation={Animation.FadeInUp}>
          <Image3Wrapper><Image path='kakaritsuke3@2x.png' /></Image3Wrapper>
        </Reveal>
        <Reveal delay={2000} animation={Animation.FadeInUp}>
          <MeritDetail color='pink'>患者さまの服薬状況をよく理解した薬剤師として、<PcBr />お薬や健康維持に関するご相談などにお応えします。<PcBr />夜間・休日などのご利用の薬局が閉まっている時間帯で、緊急のお問い合わせが<PcBr />ある場合はお電話で承ります。</MeritDetail>
        </Reveal>
      </MeritContainer>

      <HowToContainer>
        <HowToIconWrapper><Image path='zo_sm@2x.png' /></HowToIconWrapper>
        <HowToTitle>「かかりつけ薬剤師」を<SpBr />もつには？</HowToTitle>
        <HowToDetail>
          薬局で「かかりつけ薬剤師」の指名をして下さい。<PcBr />同意書に署名をいただくことで、次回から国が定める一定の要件をクリアした<PcBr />十分な経験のある薬剤師が、担当させていただきます。
        </HowToDetail>
      </HowToContainer>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px 0 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    top: 590px;
    left: 100px;
    position: absolute;
  `}
`

const SubTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    padding: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    letter-spacing: 0.14em;
    margin: 40px 0 64px;
    text-align: center;
  `}

  color: #00559F;
`

const ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 0 auto;
    width: 80%;
  `}
  ${customMedia.greaterThan("medium")`
    margin: -20px auto 0;
    width: 309px;
  `}
`

const MeritContainer = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: ${props => props.color === 'pink' ? '#CA2F68'
    : props.color === 'white' ? '#fff'
    : ''
  };

  overflow: hidden;
`

const MeritTitle = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 2.6rem;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 3.6rem;
    margin-top: 50px;
  `}

  color: ${props => props.color === 'pink' ? '#fff'
    : props.color === 'white' ? '#CA2F68'
    : ''
  };
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
`

const MeritSubTitle = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.8rem;
    margin-top: 20px;
    letter-spacing: 0.12em;
  `}

  color: ${props => props.color === 'pink' ? '#fff'
    : props.color === 'white' ? '#00559F'
    : ''
  };
  font-weight: bold;
  text-align: center;
`

const MeritDetail = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin: 20px 0 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    letter-spacing: 0.14em;
    margin: 40px 0 60px;
    text-align: center;
  `}

  color: ${props => props.color === 'pink' ? '#fff'
    : props.color === 'white' ? '#00559F'
    : ''
  };
`

const Image1Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px auto 0;
    width: 100%;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 30px auto 0;
    width: 470px;
  `}
`

const Image2Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px auto 0;
    width: 100%;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 30px auto 0;
    width: 465px;
  `}
`

const Image3Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px auto 0;
    width: 100%;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 30px auto 0;
    width: 452px;
  `}
`

const HowToContainer = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #00559F;
  overflow: hidden;
`

const HowToIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 20px auto 0;
    width: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 30px auto 0;
    width: 46px;
  `}
`

const HowToTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size 1.8rem;
    letter-spacing: 0.12em;
  `}
  ${customMedia.greaterThan("medium")`
    font-size 2.8rem;
    letter-spacing: 0.2em;
  `}

  color: #fff;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
`

const HowToDetail = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    margin: 15px 0 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    letter-spacing: 0.14em;
    line-height: 30px;
    margin: 15px 0 60px;
    text-align: center;
  `}

  color: #fff;
`

export default IndexPage
